<template>
  <div id="footer">
    <h2>I am a footer</h2>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    #footer{
        position: absolute;
        bottom: 0;
    }
</style>