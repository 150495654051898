<template>
  <div>
    <div id="nav-logo" class="nav-logo d-flex align-center flex-column justify-center">
              
        <div class="nav-menu mt-5 ml-5">
            <v-row>
                <v-col cols="12" md="4">
                <router-link to="/">
                    <v-img
                    alt="Vuetify Logo"
                    class="shrink mr-2"
                    contain
                    src="@/assets/logo.png"
                    transition="scale-transition"
                    width="auto"
                    height="51"
                    z-index="1"
                    />
                </router-link>
                </v-col>
                <v-col cols="12" md="8" class="d-flex justify-end">
                <div class="d-flex align-center justify-end nav-list me-8">
                    <router-link class="menu" :to="{name: 'Farms'}"><span>FARMS</span></router-link>
                    <router-link class="menu" :to="{name: 'HeritageTrees'}"><span>HERITAGE TREES</span></router-link>
                    <router-link class="menu" :to="{name: 'Grower'}"><span>GROWERS</span></router-link>
                    <router-link class="menu" :to="{name: 'About'}"><span>ABOUT US</span></router-link>
                    <router-link class="menu" :to="{name: 'Blog'}"><span>BLOG</span></router-link>
                </div>
                </v-col>
            </v-row>
        </div>
    </div>

    <div class="mNav">
          <v-app-bar >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <router-link to="/">
                <v-img
                alt="Vuetify Logo"
                class="shrink mr-2 m-logo"
                contain
                src="@/assets/logo.png"
                transition="scale-transition"
                width="auto"
                height="30"
                z-index="1"
                />
            </router-link>
          </v-app-bar>

          <v-navigation-drawer
            v-model="drawer"
            absolute
            left
            temporary
          >
            <v-list nav dense>
              <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
                <v-list-item>
                    <router-link class="menu" :to="{name: 'Farms'}">
                        <v-list-item-title>FARMS</v-list-item-title>
                    </router-link>
                </v-list-item>

                <v-list-item>
                    <router-link class="menu" :to="{name: 'HeritageTrees'}">
                        <v-list-item-title>HERITAGE TREES</v-list-item-title>
                    </router-link>
                </v-list-item>

                <v-list-item>
                     <router-link class="menu" :to="{name: 'Grower'}">
                        <v-list-item-title>GROWERS</v-list-item-title>
                    </router-link>
                </v-list-item>

                <v-list-item>
                     <router-link class="menu" :to="{name: 'About'}">
                        <v-list-item-title>ABOUT US</v-list-item-title>
                    </router-link>
                </v-list-item>

                <v-list-item>
                     <router-link class="menu" :to="{name: 'Blog'}">
                        <v-list-item-title>BLOG</v-list-item-title>
                    </router-link>
                </v-list-item>

              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>

        <!-- </v-card> -->
      </div>
  </div>
</template>

<script>
    export default {
    name: 'App',

    data: () => ({
        drawer: false,
        group: null,
    }),
    watch: {
        group () {
        this.drawer = false
        },
    },
    };
</script>

<style>
    .mNav{display: none;}
    #nav-logo{
        width: 100%;
    }
    .nav-menu{
        width: 100%;
    }
    .nav-list > .menu{
        float: left;
        padding: 12px;
        color: #000;
        text-decoration: none;
        font-size: 17px;
        text-align: center;
    }
    .nav-logo{
        margin-top: 50px;
    }
    .router-link-active{
        color: #403428 !important;
        font-weight: bold;
    }

    @media only screen and (max-width: 600px) {
        .mNav{display: flex;}
        .nav-logo, .nav-menu {display: none !important;}
        .nav-logo{margin-top: 0;}

        .menu{
        text-decoration: none;
        color: #000 !important;
        }
        .m-logo{width: 65% !important;}
    }
    /* .menu > span{color: #fff;} */
</style>