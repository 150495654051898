<template>
  <div>
    <div>
      <v-carousel
        cycle
        height="700"
        hide-delimiter-background
        show-arrows-on-hover
      >

        <v-carousel-item
          v-for="(item,i) in items"
          :key="i"
          :src="item.src"
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <div class="section"><navigation></navigation></div>
        </v-carousel-item>
      </v-carousel>

      <div class="sec-story">
        <div class="story-title text-center">OUR STORY</div>
        <div class="story-desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi tempore non corporis. Animi laboriosam suscipit reiciendis. Excepturi nisi dolorem veritatis assumenda fugiat! Blanditiis dolor aliquid minus culpa sunt, mollitia fugiat? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Earum saepe ratione explicabo veritatis consequatur molestiae ut deleniti, pariatur id, sed blanditiis. Modi quas quos et rem neque dolorum aliquid quis!</div>
      </div>
    </div>
    <!-- <footer-component></footer-component> -->
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import FooterComponent from '@/components/FooterComponent.vue'
import Navigation from './Navigation.vue'

export default {
  components: {
    HelloWorld,
    FooterComponent,
    Navigation
  },
  data(){
   
    return {
      items: [
        {
          src: require('../assets/pic4.png'),
        },
        // {
        //   src: require('../assets/pic2.jpeg'),
        // },
        // {
        //   src: require('../assets/pic3.jpeg'),
        // },
        // {
        //   src: require('../assets/pic1.jpeg'),
        // },
      ],
    }
  }
}
</script>

<style scoped>
  .story-title{
    font-size: 25px;
    font-weight: bold;
  }
  .sec-story{
    margin-top: 20px;
    padding: 5px 25px 5px 25px;
    text-align: justify;
  }
</style>

<style>
  .section{padding: 5px 65px 5px 65px;}

  @media only screen and (max-width: 600px) {
    .section{
      padding: 0px;
    }
  }
  /* .mNav{display: none;} */
   /* #nav-logo{
    width: 100%;
  }
  .nav-menu{
    width: 100%;
  }
  .nav-list > .menu{
    float: left;
    padding: 12px;
    color: #000;
    text-decoration: none;
    font-size: 17px;
    text-align: center;
  }
  .nav-logo{
    margin-top: 50px;
  }
  .router-link-active{
    color: #403428 !important;
    font-weight: bold;
  } */
</style>