<template>
  <v-app>
    <div>
      <!-- <div id="nav-logo" class="nav-logo d-flex align-center flex-column justify-center"> -->
        <!-- <router-link to="/">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2 nav-logo"
            contain
            src="@/assets/logo.png"
            transition="scale-transition"
            width="299"
            height="51"
            z-index="1"
          />
        </router-link> -->
        <!-- <div class="nav-menu mt-5">
          <router-link class="menu" :to="{name: 'OurVilla'}">OUR VILLA</router-link>
          <router-link class="menu" :to="{name: 'AdoptATree'}">ADOPT A TREE</router-link>
          <router-link class="menu" :to="{name: 'Blog'}">BLOG</router-link>
          <router-link class="menu" :to="{name: 'ContactUs'}">CONTACT US</router-link>
          <router-link class="menu" :to="{name: 'About'}">ABOUT</router-link>
        </div> -->
      <!-- </div> -->

      <!-- <div class="mNav">

          <v-app-bar >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          </v-app-bar>

          <v-navigation-drawer
            v-model="drawer"
            absolute
            left
            temporary
          >
            <v-list
              nav
              dense
            >
              <v-list-item-group
                v-model="group"
                active-class="deep-purple--text text--accent-4"
              >
                <v-list-item>
                  <router-link class="menu" :to="{name: 'OurVilla'}">
                    <v-list-item-title>OUR VILLA</v-list-item-title>
                  </router-link>
                </v-list-item>

                <v-list-item>
                  <router-link class="menu" :to="{name: 'AdoptATree'}">
                    <v-list-item-title>ADOPT A TREE</v-list-item-title>
                  </router-link>
                </v-list-item>

                <v-list-item>
                  <router-link class="menu" :to="{name: 'Blog'}">
                    <v-list-item-title>BLOG</v-list-item-title>
                  </router-link>
                </v-list-item>

                <v-list-item>
                  <router-link class="menu" :to="{name: 'ContactUs'}">
                    <v-list-item-title>CONTACT US</v-list-item-title>
                  </router-link>
                </v-list-item>

                <v-list-item>
                  <router-link class="menu" :to="{name: 'AboutUs'}">
                    <v-list-item-title>ABOUT US</v-list-item-title>
                  </router-link>
                </v-list-item>

              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
      </div> -->
      
      

      <!-- <v-spacer></v-spacer> -->

      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        Others
      </v-btn> -->
    </div>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    group: null,
  }),
  watch: {
    group () {
      this.drawer = false
    },
  },
};

</script>
<style>

  @media only screen and (max-width: 600px) {
    .mNav{display: flex;}
    .nav-logo, .nav-menu {display: none !important;}
    .nav-logo{margin-top: 0;}

    .menu{
      text-decoration: none;
      color: #000 !important;
    }
    
  }
</style>
  
<style scoped>
 
 /* #nav-logo{
    width: 100%;
  }
  .nav-menu{
    width: 100%;
  }
  .nav-menu > .menu{
  float: left;
  padding: 12px;
  color: #000;
  text-decoration: none;
  font-size: 17px;
  width: 20%; 
  text-align: center;
  }
  .nav-logo{
    margin-top: 50px;
  } */

  
</style>
